import { nameof } from '../utils/nameof';
import { ISAPPaging } from './api/sap-api';
import {
  IMaterialData,
  IMaterialSearch,
  IMaterialsSearch,
  IMaterialsSearchResponse,
  ISAPResultSetResponse,
  MATERIAL_TYPE_MRO_MATERIALS,
} from './sap-result-types';
import * as materialCreationApi from './api/material-creation-api';

let searchMaterialsAbortController: AbortController | undefined;

export const searchMaterials = async (
  searchText: string,
  abortPrevious = false,
  paging: ISAPPaging,
): Promise<IMaterialsSearchResponse> => {
  if (abortPrevious && searchMaterialsAbortController) searchMaterialsAbortController.abort();
  searchMaterialsAbortController = new AbortController();

  const filterString = `General/MaterialType eq '${MATERIAL_TYPE_MRO_MATERIALS}' and Plant/Plant eq '500' and Search eq true`;

  const searchString = `search='*${searchText.split(' ').join('*')}*'`;

  const selectString = `${nameof<IMaterialsSearch>('MaterialNumber')},${nameof<IMaterialsSearch>(
    'Text',
  )},${nameof<IMaterialsSearch>('General')},${nameof<IMaterialsSearch>('Classification')}`;

  const uri = `yteamcenter-srv/materials?filter=${filterString}&select=${selectString}&search=${searchString}&$skip=${paging.skip}&$top=${paging.top}`;

  try {
    const response = await materialCreationApi.get<ISAPResultSetResponse<IMaterialsSearch>>(
      uri,
      searchMaterialsAbortController,
    );

    return {
      materials: response.d.results,
      totalCount: Number(response.d.__count),
    };
  } catch (error) {
    if ((error as Error).message === 'No data found') return { materials: [], totalCount: 0 };
    throw error;
  }
};

const searchOnSpecificAttribute = async (
  filter: Array<{
    attribute: string;
    value: string;
  }>,
): Promise<IMaterialSearch[]> => {
  const materialNumberAttribute = nameof<IMaterialData>('MaterialNumber');
  const descriptionAttribute = nameof<IMaterialData>('MaterialDescription');
  const manufacturerAttribute = nameof<IMaterialData>('Manufacturer');

  const manufacturerPartNumberAttribute = nameof<IMaterialData>('ManufacturerPartNumber');

  const filterString = `MaterialType eq '${MATERIAL_TYPE_MRO_MATERIALS}' and ${filter
    .map((v) => `${v.attribute} eq '${v.value}'`)
    .join(' and ')}`;

  const selectString = `${materialNumberAttribute},${descriptionAttribute},${manufacturerPartNumberAttribute},${manufacturerAttribute}`;

  const uri = `material-odata-service/material-data-set?filter=${filterString}&$select=${selectString}`;
  const response = await materialCreationApi.get<ISAPResultSetResponse<IMaterialData>>(uri, searchMaterialsAbortController);

  return response.d.results.map((i) => ({
    MaterialNumber: i.MaterialNumber,
    MaterialDescr: i.MaterialDescription,
    Manufacturer: i.Manufacturer,
    ManufacturerPartNumber: i.ManufacturerPartNumber,
  }));
};

export const doesMaterialExist = async (materialNumber: string) => {
  const results = await searchOnSpecificAttribute([
    { attribute: nameof<IMaterialData>('MaterialNumber'), value: materialNumber },
  ]);

  return results.length === 1;
};

export const doesManufacturerPartNumberExist = async (
  manufacturer: string,
  manufacturerPartNumber: string,
) => {
  const results = await searchOnSpecificAttribute([
    { attribute: nameof<IMaterialData>('ManufacturerPartNumber'), value: manufacturerPartNumber },
  ]);

  return results.some(
    (res) =>
      res.Manufacturer === manufacturer && res.ManufacturerPartNumber === manufacturerPartNumber,
  );
};

export const doesEanNumberExist = async (eanCategory: string, eanNumber: string) => {
  const results = await searchOnSpecificAttribute([
    { attribute: nameof<IMaterialData>('Ean11Number'), value: eanNumber },
    { attribute: nameof<IMaterialData>('EanCategory'), value: eanCategory },
  ]);

  return results.length > 0;
};
