import { useQuery, useQueryClient } from 'react-query';
import { getUnitsOfMeasureMapped } from './mappers/units-of-measure';
import { QueryKeys } from './query-keys';

const key = [QueryKeys.UnitsOfMeasure];

const queryFn = () => {
  console.log(`queryFn`);
  return getUnitsOfMeasureMapped();
};

export const useUnitsOfMeasure = () => {
  console.log(`useUnitsOfMeasure`);
  const queryClient = useQueryClient();

  const query = useQuery(key, queryFn, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  return {
    ...query,
    errorMessage: query.error ? (query.error as Error).message : undefined,
    reset: () => queryClient.setQueryData(key, undefined),
  };
};
