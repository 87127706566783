import { nameof } from '../utils/nameof';
import {
  IManufacturersSearch,
  ISAPResultSetResponse,
} from './sap-result-types';
import * as materialCreationApi from './api/material-creation-api';

let searchManufacturersAbortController: AbortController | undefined;

export const searchManufacturers = async (
  searchText: string,
  abortPrevious = false,
): Promise<IManufacturersSearch[]> => {
  if (abortPrevious && searchManufacturersAbortController)
    searchManufacturersAbortController.abort();
  searchManufacturersAbortController = new AbortController();

  const searchString = `search='*${searchText.split(' ').join('*')}*'`;

  const selectString = nameof<IManufacturersSearch>('Name');

  const uri = `yteamcenter-srv/vendors?search=${searchString}&select=${selectString}`;

  try {
    const response = await materialCreationApi.get<ISAPResultSetResponse<IManufacturersSearch>>(
      uri,
      searchManufacturersAbortController,
    );

    return response.d.results;
  } catch (error) {
    if ((error as Error).message === 'No data found') return [];
    throw error;
  }
};
