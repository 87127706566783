import { getUnitsOfMeasure } from '../../network/get-units-of-measure';
import { IUnitOfMeasure } from '../models/units-of-measure';

const allowedUnitIds = ['PC', 'M', 'KG', 'ROL', 'M2', 'L', 'SET', 'BAG', 'PAC'];

export const getUnitsOfMeasureMapped = async (): Promise<IUnitOfMeasure[]> => {
  console.log(`getUnitsOfMeasureMapped`);
  const data = await getUnitsOfMeasure();

  const mappedData = data.map((r) => ({
    id: r.UnitId,
    description: r.UnitDescr,
  }));

  console.log('Mapped units of measure:', mappedData);

  return mappedData.filter((value) => allowedUnitIds.some((unitId) => unitId === value.id));
};
