import {
  IMaterialGroup,
  ISAPResultSetResponse,
} from './sap-result-types';
import * as materialCreationApi from './api/material-creation-api';

export const getMaterialGroups = async (): Promise<IMaterialGroup[]> => {
  const selectString = 'MaterialGroupId,Text,Description';

  const filterString =
    "MaterialGroupId ne '0320' and MaterialGroupId ne '0321' and MaterialGroupId ne '0322' and MaterialGroupId ne '0323' and MaterialGroupId ne '0324' and MaterialGroupId ne '0325'";

  const uri = `yteamcenter-srv/material-groups?filter=${filterString}&select=${selectString}`;
  const response = await materialCreationApi.get<ISAPResultSetResponse<IMaterialGroup>>(uri);

  return response.d.results;
};
