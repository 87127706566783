import { nameof } from '../utils/nameof';
import { NotFoundError } from './errors/not-found-error';
import {
  IMaterialData,
  ISAPResultSetResponse,
} from './sap-result-types';
import * as materialCreationApi from './api/material-creation-api';

export const getMaterial = async (materialNumber: string): Promise<IMaterialData> => {
  const materialNumberAttribute = nameof<IMaterialData>('MaterialNumber');

  const manufacturerPartNumberAttribute = nameof<IMaterialData>('ManufacturerPartNumber');

  const vendorMaterialAttribute = nameof<IMaterialData>('VendorMaterial');

  const materialDescriptionAttribute = nameof<IMaterialData>('MaterialDescription');

  const sizeAttribute = nameof<IMaterialData>('Size');
  const materialGroupAttribute = nameof<IMaterialData>('MaterialGroup');
  const unitOfMeasureAttribute = nameof<IMaterialData>('BaseUnit');
  const industryStandardAttribute = nameof<IMaterialData>('IndustryStandard');

  const filterString = `MaterialNumber eq '${materialNumber}'`;

  const selectString = `${materialNumberAttribute},${manufacturerPartNumberAttribute},${vendorMaterialAttribute},${materialDescriptionAttribute},${sizeAttribute},${materialGroupAttribute},${industryStandardAttribute},${unitOfMeasureAttribute}`;

  const uri = `material-odata-service/material-data-set?filter=${filterString}&$select=${selectString}`;
  const response = await materialCreationApi.get<ISAPResultSetResponse<IMaterialData>>(uri);

  if (response.d.results.length === 0) throw new NotFoundError();

  return response.d.results[0];
};
