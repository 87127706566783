import { IBaseUnit, ISAPResultSetResponse } from './sap-result-types';
import * as materialCreationApi from './api/material-creation-api';

export const getUnitsOfMeasure = async (): Promise<IBaseUnit[]> => {
  const uri = `material-odata-service/sh-base-unit-set`;
  console.log(`Requesting units of measure from URI: ${uri}`);

  const response = await materialCreationApi.get<ISAPResultSetResponse<IBaseUnit>>(uri);
  console.log('Received response:', response);

  return response.d.results;
};