import { nameof } from '../utils/nameof';
import { ISAPResultSetResponse, IVendorsSearch } from './sap-result-types';
import * as materialCreationApi from './api/material-creation-api';

let searchVendorsAbortController: AbortController | undefined;

export const searchVendors = async (
  searchText: string,
  abortPrevious = false,
): Promise<IVendorsSearch[]> => {
  if (abortPrevious && searchVendorsAbortController) searchVendorsAbortController.abort();
  searchVendorsAbortController = new AbortController();

  const searchString = `search='*${searchText.split(' ').join('*')}*'`;

  const selectString = `${nameof<IVendorsSearch>('Name')},${nameof<IVendorsSearch>(
    'SupplierNumber',
  )}`;

  const uri = `yteamcenter-srv/vendors?search=${searchString}&select=${selectString}`;

  try {
    const response = await materialCreationApi.get<ISAPResultSetResponse<IVendorsSearch>>(
      uri,
      searchVendorsAbortController,
    );

    return response.d.results;
  } catch (error) {
    if ((error as Error).message === 'No data found') return [];
    throw error;
  }
};
